import { writable } from "svelte/store";
import { target_network } from "./web3/targetNetwork";
import {isGold,isGoldLoaded} from "./transactions";

export let imgs = {};
export let profile = {};
export let samurai = [];

export let images = writable(imgs);
export let profiles = writable(profile);
export let ascenders = writable(samurai);

let imageQueue = [];
let pendingImage = null;

const URI_BASE = process.env.URI_BASE;
const OS_URI = process.env.OPENSEA_URI;
const OS_PARAMS = process.env.OPENSEA_PARAMS;
const OS_KEY = process.env.OPENSEA_API_KEY;
const RINKEBY_KEY = process.env.TESTNET_URI;

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function useState() {
	const { subscribe, set, update } = writable(false);
	return {
		subscribe,
		updateState: () => { update(value => !value) },
		reset: () => set(false),
		force: (boolean) => set(boolean)
	}
}

export function interceptTokenId(tokenId) {
	return tokenId;
}

export const requestImage = (tokenId) => {
	tokenId = tokenId.toString();
	if (!imgs[tokenId] && !imageQueue.includes(tokenId)) {
		imageQueue.push(tokenId);
	}
	loadNextImage();
};

// METHOD: GET
// Fetch Single Ronin By ID
export const fetchProfile = async (tokenId) => {
	tokenId = interceptTokenId(tokenId);
	const url = `${OS_URI}` + tokenId + `${OS_PARAMS}`
	try {
		let response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				"x-api-key": OS_KEY
			},
		})
		let ronin = await response.json();
		profile = {
			name: ronin.assets[0].name,
			url: ronin.assets[0].image_preview_url,
			traits: ronin.assets[0].traits,
			owner: ronin.assets[0].owner,
			audio: ronin.assets[0].animation_url?.toString(),
			state: "loaded",
			trueID: tokenId
		}
		profiles.set(profile);
	} catch (err) {
		console.log(err)
		let ronin = {
			error: 'This Ronin could not be found. Please try again.'
		};
	}
};


// METHOD: GET
// Fetch Single Ronin By ID
export const fetchProfileAndCache = async (tokenId) => {
	tokenId = interceptTokenId(tokenId);
	const secondary_url = `${OS_URI}` + tokenId + `${OS_PARAMS}`;
	const primary_url = `${URI_BASE}` + tokenId;
	try {
		let response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				"x-api-key": OS_KEY
			},
		})
		let ronin = await response.json();
		profile = {
			name: ronin.assets[0].name,
			url: ronin.assets[0].image_preview_url,
			traits: ronin.assets[0].traits,
			owner: ronin.assets[0].owner,
			audio: ronin.assets[0].animation_url?.toString(),
			state: "loaded",
			trueID: tokenId
		}
		profiles.set(profile);
	} catch (err) {
		console.log(err)
		let ronin = {
			error: 'This Ronin could not be found. Please try again.'
		};
	}
};

// METHOD: GET
// Sigle IPFS Request

export const fetchIPFSProfile = async (tokenId) => {
	isGoldLoaded.set(false);
	let url;

	tokenId = interceptTokenId(tokenId);

	if (target_network.chainId === 1) {
		url = `${URI_BASE}` + tokenId
	} else {
		url = `${RINKEBY_KEY}` + tokenId + ".json"
	}

	try {
		let response = await fetch(url, {
			method: 'GET',
		})
		let ronin = await response.json();

		console.log(">>");
		console.log(ronin);

		profile = {
			name: ronin.name,
			url: ronin.image,
			traits: ronin.attributes,
			state: "loaded",
			trueID: tokenId
		}

		console.log(profile);

		profiles.set(profile);

		isGold.set(JSON.stringify(ronin.attributes).toString().includes("金色の浪士"));
		isGoldLoaded.set(true);
	} catch (err) {
		console.log(err)
	}
};

// METHOD: GET
// Fetch Array of Ronin for Ascension
// First request IPFS, if it fails, fetch OpenSea

export let FAILED_REQUESTS = []; // An Array of all the failed IDs

export let cached = writable(FAILED_REQUESTS);

export const fetchAscenders = async (tokenId) => {
	isGoldLoaded.set(false);

	tokenId = interceptTokenId(tokenId);

	let url;

	let secondary_url = `${OS_URI}` + tokenId + `${OS_PARAMS}`;

	target_network.chainId === 1 ? url = `${URI_BASE}` + tokenId : url = `${RINKEBY_KEY}` + tokenId + ".json"

	try {
		// Makes a request to IPFS, and if it fails, cache the tokenID
		try {
			let response = await fetch(secondary_url, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					"x-api-key": OS_KEY
				},

			})
			let ronin = await response.json();

			profile = {
				name: ronin.assets[0].name,
				url: ronin.assets[0].image_preview_url,
				traits: ronin.assets[0].traits,
				owner: ronin.assets[0].owner,
				audio: ronin.assets[0].animation_url?.toString(),
				state: "loaded",
				trueID: tokenId
			}
			profiles.set(profile);
			samurai.push(profile);
			ascenders.set(samurai)

			isGold.set(JSON.stringify(ronin.attributes).toString().includes("金色の浪士"));
			isGoldLoaded.set(true);
		} catch (err) {
			console.log(err)
			FAILED_REQUESTS.push(tokenId)
			cached.set(FAILED_REQUESTS)
		}

		// If current tokenID is in the cache due to failure, make a request to OpenSea
		if (FAILED_REQUESTS.includes(tokenId) === true) {
			FAILED_REQUESTS.splice(FAILED_REQUESTS.indexOf(tokenId));
			FAILED_REQUESTS.push(tokenId);

			try {
				let response = await fetch(url, {
					method: 'GET',
				})
				let ronin = await response.json();
				profile = {
					name: ronin.name,
					url: ronin.image,
					traits: ronin.attributes,
					state: "loaded",
					trueID: tokenId
				}
				profiles.set(profile);
				samurai.push(profile);
				ascenders.set(samurai)

				isGold.set(JSON.stringify(ronin.attributes).toString().includes("金色の浪士"));
				isGoldLoaded.set(true);
			} catch (err) {
				FAILED_REQUESTS.push(tokenId)
				cached.set(FAILED_REQUESTS)
				console.warn(`Request for ${tokenId} failed.`)
			}
		}
	} catch (err) {
		console.warn(` Error at ${tokenId}`, err)
	}
}

const loadNextImage = () => {
	if (pendingImage === null && imageQueue.length > 0) {
		pendingImage = imageQueue.splice(0, 1)[0];
		imgs[pendingImage] = {
			state: "loading",
			uri: "",
		};
		images.set(imgs);
		getTokenImage(pendingImage);
	}
};

const getTokenJSON = (tokenId, error, callback) => {
	let uri = URI_BASE + tokenId;
	var xhr = new XMLHttpRequest();
	xhr.open("GET", uri, true);
	xhr.send();
	xhr.onerror = (err) => {
		error(err);
	};
	xhr.onreadystatechange = function () {
		if (this.readyState == 4 && this.status == 200) {
			try {
				let metadata = JSON.parse(xhr.responseText);
				callback(metadata);
			} catch (err) {
				error(err);
			}
		}
	};
};

const getTokenImage = (tokenId) => {
	getTokenJSON(
		tokenId,
		(err) => {
			pendingImage = null;
			imgs[tokenId].state = "error";
			images.set(imgs);
			requestImage(tokenId);
		},
		(metadata) => {
			pendingImage = null;
			imgs[tokenId].state = "loaded";
			imgs[tokenId].uri = metadata.image;
			loadNextImage();
		},
	);
};

const getTokenProfile = (tokenId) => {
	getTokenJSON(
		tokenId,
		(err) => {
			pendingImage = null;
			profile[tokenId].state = 'error';
			profiles.set(profile);
			console.error(tokenId, err)
		},
		(metadata) => {
			let pendingProfile = null;
			profile[tokenId].name = metadata.name;
			profile[tokenId].state = "loaded";
			profile[tokenId].uri = metadata.image;
			profile[tokenId].attributes = metadata.attributes.copyWithin(0, 9)
			// console.log(metadata)
			profiles.set(profile)
		},
	);
};

export const isGoldRonin = (tokenId, callback) => {
	let errorCount = 0;
	getTokenJSON(
		tokenId,
		(err) => {
			if (errorCount > 10) {
				callback(err);
			} else {
				setTimeout(() => {
					isGoldRonin(tokenId, callback);
				}, 1000);
			}
		},
		(metadata) => {
			if (JSON.stringify(metadata).includes("金色の浪士")) {
				callback(false, true);
			} else {
				callback(false, false);
			}
		},
	);
};
