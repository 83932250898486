<script>
	import Roadmap from '../../public/assets/images/Kamon/RoadmapKamon.svelte';
	import Ascension from '../../public/assets/images/Kamon/AscensionKamon.svelte'
	import Mint from '../../public/assets/images/Kamon/MintKamon.svelte';
	import Game from '../../public/assets/images/Kamon/BoatGameKamon.svelte';
	import Kitsune from '../../public/assets/images/Kamon/KitsuneKamon.svelte'
	import {fade} from 'svelte/transition';
	import {navigateTo} from 'svelte-router-spa';

	import { createEventDispatcher } from 'svelte';

	let w;
	let y;
	let screenHeight;

	let mobile = false;

	const clickBoatGame = () => {navigateTo('BoatGame')};
	const clickAscend = () => {navigateTo('ascend')};
	const clickKitsune = () => {
		navigateTo('kitsune');
		scrollTo(0, 0);
	};
	const clickMint = () => {navigateTo('mint')};
	const clickRoadmap = () => {window.location.assign('/roadmap')};

	const scrollToKitsune = () => {document.getElementById('04_Kitsune').scrollIntoView()}

	$: w < 500 ? mobile === true : mobile === false;

	$: console.log(`--> ScreenHeight ${screenHeight}` )

</script>

<svelte:window bind:scrollY={y} bind:innerWidth={w} bind:innerHeight={screenHeight} />

<div in:fade={{delay: 100, duration: 1000}} out:fade={{delay: 100, duration: 1000}}>
	<div class="container mx-auto px-10 lg:px-20 xl:px-80 w-full">
		<div class=" grid grid-cols-2 md:grid-cols-4 gap-x-20 gap-y-10 z-10">
			<div class="flex flex-col items-center justify-center md:mt-20 kamon-menu-item" on:click={clickMint}>
				<div class="w-14 md:w-20 h-16 md:h-20">
					<Mint/>
				</div>
				<h3 class="mt-2 main-font text-xs md:text-base">MINT</h3>
			</div>
			<div class="flex flex-col items-center justify-center kamon-menu-item" on:click={clickKitsune}>
				<div class="w-14 md:w-20 h-16 md:h-20">
					<Kitsune/>
				</div>
				<h3 class="mt-2 main-font text-xs md:text-base">KITSUNE</h3>
			</div>
			<div class="flex flex-col items-center justify-center kamon-menu-item" on:click={clickAscend}>
				<div class="w-14 md:w-20 h-16 md:h-20">
					<Ascension/>
				</div>
				<h3 class="mt-2 main-font text-xs md:text-base">ASCENSION</h3>
			</div>
			<!-- <div class="kamon_chamber kamon_chamber_high mx-auto" on:click={clickBoatGame}> -->
			<!-- <div class="kamon_chamber kamon_chamber_high mx-auto">
				<Game class="kamon_icon"/>
				<h3 class="kamon_id">BOAT GAME</h3>
			</div> -->
			<!-- <div class="kamon_chamber kamon_chamber_low mx-auto" on:click={clickRoadmap}> -->
			<div class="flex flex-col items-center justify-center md:mt-20 kamon-menu-item" on:click={clickRoadmap}>
				<div class="w-14 md:w-20 h-16 md:h-20">
					<Roadmap/>
				</div>
				<h3 class="mt-2 main-font text-xs md:text-base">ROADMAP</h3>
			</div>
		</div>

	</div>
</div>
