<script>
	import {data, useAlchemy, claimRonin} from '../../stores/manager';
	import {fade} from 'svelte/transition';

	const initiateClaim = () => {
		claimRonin(unclaimed)
	};

  $: unclaimed = Number($data.myRoninReserveCount.value);
</script>

<div class="claim_alert hover:animate-none">
	{#if useAlchemy !== true}
		{#if unclaimed !== 0}
				<div class="" in:fade={{ delay: 100, duration: 100 }} out:fade={{delay: 100, duration: 100}}>
					<button class="base-btn" on:click={initiateClaim}>	<p class="">You have <span class="section_highlight">{unclaimed}</span> unclaimed Ronin.</p></button>
				</div>
		{/if}
	{/if}
</div>