<script>
	// import {state, me} from "../stores/contracts";
	import {data, useAlchemy} from '../../stores/manager';
	import {state as state_contracts} from '../../stores/contracts';
	import {lock, unlock} from "../../stores/web3/wallet";
	import {fade} from 'svelte/transition';
	import {togglePanel} from '../../stores/ui.js'

	let networkAlert = false;
	let connectionError = false;

	$: $state_contracts === 'network' ? networkAlert = true : networkAlert = false;
	$: $state_contracts === "error" ? connectionError = true : connectionError = false;

	const handleDebugPanel = () => { togglePanel() }

	let w;


</script>

<svelte:window bind:innerWidth={w}/>

<header class="navigation" in:fade out:fade>
	<nav class="mobile_menu">
	</nav>
	<nav>
	</nav>
	{#if $useAlchemy}
	<div class="wallet_controls {w < 500 ? "-mr-5":"-mr-12"}">
		<div class="status_light_off animate-pulse duration-75" on:click={handleDebugPanel}></div>
		<button class="base-btn-connect" on:click={unlock}>
			CONNECT WALLET
		</button>
	</div>
	{:else if $state_contracts === "loaded"}
	<div class="wallet_controls {w < 500 ? "-mr-5":"-mr-12"}">
		{#if networkAlert === false}
			<div class="status_light_on animate-pulse" on:click={handleDebugPanel}></div>
		{/if}
		<button class="{networkAlert === false ? "btn-connected":"btn-incorrect-network"}" on:click={lock}>
			{#if networkAlert === true} 
				<p class="animate-pulse text-yellow-200 mt-1 ">INCORRECT NETWORK</p>
				{:else if connectionError === true}
				<p class="animate-pulse text-red-200 mt-1 ">ERROR</p>
			{:else}
				CONNECTED
			{/if}
		</button>
	</div>
	{:else}
	<div class="wallet_controls {w < 500 ? "-mr-5":"-mr-12"}">
		{#if networkAlert === false}
			<div class="status_light_on animate-pulse" on:click={handleDebugPanel}></div>
		{/if}
		<button class="{networkAlert === false ? "btn-connected":"btn-incorrect-network"}" on:click={lock}>
			{#if networkAlert === true} 
				<p class="animate-pulse text-yellow-200 mt-1 ">INCORRECT NETWORK</p>
				{:else if connectionError === true}
				<p class="animate-pulse text-red-200 mt-1 ">ERROR</p>
			{:else}
				CONNECTED
			{/if}
		</button>
	</div>
	{/if}
</header>

<style>

</style>


