import {writable} from "svelte/store";

const URI_BASE = "https://72r3vrw8r4.execute-api.us-east-2.amazonaws.com/default/kitsune-metadata-microservice?token=";
const GATEWAY_STEM = "gatewayStem/";

export const OPENSEA_STEM = "https://opensea.io/assets/ethereum/0xfc2d7c0fd2d353923d47ccd2409f8ee19e86fd29/";

const metadataQueue = [];
let metadataPending = false;

// const imageQueue    = [];
// let imagePending = false;

let _metadata = {
}
export let metadata = writable(_metadata);

export let selectedKitsune = writable(-1);

export function selectKitsune(tokenId){
    selectedKitsune.set(tokenId);
}

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function requestKitsuneMetadata(tokenId){
    tokenId = tokenId.toString();

    // console.log("request kitsune:",tokenId)

    if(!(_metadata[tokenId] || metadataQueue.includes(tokenId))){
        metadataQueue.unshift(tokenId);
    }
    loadNextMetadata();
}
function loadNextMetadata(){

    // console.log("load next metadata...")
    if(metadataPending) return;
    // console.log("    passes..")

    metadataPending = true;

    let tokenId = metadataQueue.shift();

    // console.log(">>",tokenId);

    _metadata[tokenId] = {
        id: tokenId,
        name: "",
        state: "loading",
        image: "",
        attributes: [],
    }
    metadata.set(_metadata);

    async function err(tokenId){
        metadataPending = false;

        _metadata[tokenId].state = "error";
        metadata.set(_metadata);

        metadataQueue.push(tokenId);

        await sleep(2000);

        loadNextMetadata();
    }
    function callback(__metadata){


        metadataPending = false;

        _metadata[tokenId].state = "loaded";
        _metadata[tokenId].name  = __metadata.name;
        _metadata[tokenId].image = __metadata.image;
        _metadata[tokenId].attributes  = __metadata.attributes;


        metadata.set(_metadata);


        loadNextMetadata();
    }
    loadMetadata(tokenId,err,callback);
}

function loadMetadata(tokenId, error, callback){
    let uri = URI_BASE + tokenId;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", uri, true);
    xhr.send();
    xhr.onerror = (err) => {

        error(err);
    };
    xhr.onreadystatechange = function () {

        if (this.readyState == 4 && this.status == 200) {

            try {
                let metadata = JSON.parse(xhr.responseText);


                callback(metadata);
            } catch (err) {
                error(err);
            }
        }
    };
}
