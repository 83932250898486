<script>
	import {fetchAscenders, ascenders} from '../stores/metadata';
	import {fade} from 'svelte/transition';
	import Spinner from '../components/shared/Spinner.svelte';

	import {data} from "../stores/manager";

	import {metadata, requestKitsuneMetadata} from "../stores/kitsuneMetadata";


	export let id;
	export let selected = false;

	let thisId;
	let status = false;
	let error = false;

	let thisURI = "";

	let token;

	// let result;

	// Set whether the Kitsune has been claimed for this Ronin id.


	$:{
		token = id;
		requestKitsuneMetadata(token);
	}

	$:{
		if(token && $metadata[token] && $metadata[token].state === "loaded"){
			thisURI = $metadata[token].image;
		}
	}

	var img = document.querySelector('img');

	//  $:{
    //     if (id !== thisId) {
    //         thisId = id;
    //         fetchAscenders(thisId);
    //     }
	//
	// 			result = $ascenders.filter(token => token.trueID === id);
    // }

		$: {
			// if (result[0]) {
			// 	thisURI = result[0].url;
			// }
		}

	const imgBroken = () => {
		status = true;
		error = true;
	}

	const isLoaded = () => {
		status = true;
	}

	$: {
		if (img.complete) {
		isLoaded()
			} else {
				img.addEventListener('load', isLoaded);
				img.addEventListener('error', function() {
					status = false;
					error = true;
				})
			}
		}
</script>

<section class="cursor-pointer mt-1 mb-2 relative {selected?'ronin-selected':''}" in:fade={{delay: 100, duration: 800}} out:fade={{delay: 100, duration: 800}}>
	{#if thisURI === ""}
	<div class="placeholder ronin-img" on:click>
		<Spinner/>
		<!-- <p class="ronin_indicator">{token}</p> -->
	</div>
	{:else if status === true && error === true}
		<div class="placeholder ronin-img" on:click on:error>
			<Spinner/>
			<p class="ronin_indicator">Kitsune {token}</p>
		</div>
	{:else if status === true }
			<img
				on:click
				on:error={imgBroken}
				class = "w-100 md:w-60 max-w-100  ronin-img {selected?'ronin-img-selected':''}"
				src={thisURI} alt="Kitsune {token}"/>
	{:else if status === false && thisURI !== ""}
			<div class="placeholder ronin-img" on:click>
					<!-- <Spinner/>
					<p class="ronin_indicator">Ronin {id}</p> -->
			</div>
	{/if}
</section>