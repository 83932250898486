<script>
	import RoninImage from './RoninImage.svelte'
	import KitsuneImage from './KitsuneImage.svelte'

    import {fetchProfile, fetchIPFSProfile} from '../stores/metadata'
    import {createEventDispatcher} from 'svelte';

    import {selectKitsune} from "../stores/kitsuneMetadata";

    export let tokensSorted = [];
    export let highlighted = false;
	export let selectedId;
    export let returned;
    let w;

    export let selectedType;

    const dispatch = createEventDispatcher();

	const clickRonin = (id) => {
        if(selectedId === id && selectedType === "ronin"){
            selectedType = null;
            selectedId = -1
        }else{
            selectedId = id;
            selectedType = "ronin";
        }
	};

    const clickKitsune = (id) => {
        if(selectedId === id && selectedType === "kitsune"){
            selectedType = null;
            selectedId = -1
        }else{
            selectedId = id;
            selectedType = "kitsune";
        }
	};




    let tokenId = "";
    
    let tokenCache = [];

    const queueRonin = async (id) => {
        tokenId = id;
        tokenCache.push(tokenId);
        await fetchIPFSProfile(id);
        dispatch('readyProfile', "ronin");
        highlighted = true;
        returned = false;
    };

    const queueKitsune = async  (id) => {
        tokenId = id;

        // await fetchIPFSProfile(id);
        selectKitsune(id);
        dispatch('readyProfile', "kitsune");

        highlighted = true;
        returned = false;
    }
</script>

<svelte:window bind:innerWidth={w}/>



<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-4 mr-6">
    {#each tokensSorted as token}
        {#if token.type === "ronin"}

            <div class="ronin_avatar">
                <RoninImage
                        id={token.tokenId}
                        selected={selectedId === token.tokenId && selectedType === "ronin"}
                        on:click={()=>{
                            clickRonin(token.tokenId)
                            // Fwd to profile here
                            queueRonin(token.tokenId)
                        }}
                />
                <h6 class="ronin_id">#{token.tokenId}</h6>
            </div>
        {:else}
            <div class="kitsune_avatar">
                <KitsuneImage
                        id={token.tokenId}
                        selected={selectedId === token.tokenId && selectedType === "kitsune"}
                        on:click={()=>{
                            clickKitsune(token.tokenId)
                            // Fwd to profile here
                            queueKitsune(token.tokenId)
                        }}
                />
                <h6 class="ronin_id">#{token.tokenId}</h6>
            </div>
        {/if}
    {/each}
</div>