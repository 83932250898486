<script>
	import {fetchProfile} from '../stores/metadata'
	import {state, data} from '../stores/manager'
	import {createEventDispatcher} from 'svelte'

	// export let showProfile = false;

	const dispatch = createEventDispatcher()


	$: tokenId = ""
	let tokenCache = []

	const handleSubmit = async (tokenId) => {
		tokenCache.push(tokenId)
		await fetchProfile(tokenId)
		dispatch('getRonin', "profile has been requested")
	}

</script>

<div>
	<input type="text" bind:value={tokenId} placeholder="Enter your Ronin ID"/>
	<button on:click={handleSubmit(tokenId)}>Search</button>
	<!-- <button on:click={clearOnSearch}>Clear</button> -->
</div>