<script>
	import {FogTop, FogMid} from '../../../public/assets/urls'
</script>

<div class="haze_body battleground relative z-0">
	<div class="haze_high">
		<img class="haze_high" src={FogTop} alt=""/>
	</div>
	<div class="haze_mid ">
		<img class="haze_mid" src={FogMid} alt=""/>
	</div>
	<div class="">
		<img class="haze_low" src={FogTop} alt=""/>
	</div>
</div>

<style>
</style>
