<script>
	import {Logo, Twitter, Medium, IG, FB, Discord} from '../../../public/assets/urls';

	let w;

	let discord = 'https://discord.com/invite/bigheadhq';
	let twitter = 'https://twitter.com/bigheadhq';
	let instagram = 'https://www.instagram.com/bigheadhq/';
	let facebook = 'https://www.facebook.com/bigheadhq';
	let medium = 'https://www.medium.com/@bigheadhq';
	let tos = 'https://docs.google.com/document/d/1cOnQLufqUdk9TvIWZhKBBKC_qRTC_p05';
	let policy = 'https://docs.google.com/document/d/1qDKvadpweeD_ZgZPvvGXPxucrx2UTCSG';
	let faqs = 'https://docs.google.com/document/d/1HKhKRuZoSnkRlM1vmLER6-4qd5IxXSzqN8Jtvyp89xA/';

	const newWindow = (url) => { window.open(url) };

	const clickForTwitter = () => { newWindow(twitter) };
	const clickForMedium = () => { newWindow(medium) };
	const clickForFacebook = () => { newWindow(facebook) };
	const clickForDiscord = () => { newWindow(discord) };
	const clickForInstagram = () => { newWindow(instagram) };

	const sendEmail = () => {}

	const clickForTerms = () => { newWindow(tos) };
	const clickForPrivacyPolicy = () => { newWindow(policy) };
	const clickForFAQs = () => { newWindow(faqs) };
</script>

<svelte:window bind:innerWidth={w}/>

<footer class="{ w > 500 ? "general_layout":"relative footer-fill"}">
	<!-- Make 4 Columns Grid -->
	<div class="footer_content">
		{#if w > 500}
		<!-- Desktop Layout Begins -->
			<div class="bighead_logo">
				<img src={Logo} alt="" class="footer_logo ml-10" />
				<div class="inline-block contact_row">
					<img src={Twitter} alt="" class="contact_icon" on:click={clickForTwitter} />
					<img src={Discord} alt="" class="contact_icon" on:click={clickForDiscord} />
					<img src={IG} alt="" class="contact_icon" on:click={clickForInstagram} />
					<img src={Medium} alt="" class="contact_icon" on:click={clickForMedium}/>
					<img src={FB} alt="" class="contact_icon" on:click={clickForFacebook} />
				</div>
			</div>
			<div class="footer_links z-50">
				<ul>
					<li class="footer_highlight">Support</li>
					<li class="footer_text_link" on:click={clickForTerms}>Terms of Service</li>
					<li class="footer_text_link" on:click={clickForPrivacyPolicy}>Privacy Policy</li>
					<li class="footer_text_link" on:click={clickForFAQs}>FAQs</li>
				</ul>
			</div>
			<div class="footer_links z-50">
				<ul>
					<li class="footer_highlight">Connect</li>
					<li class="footer_text_link" on:click={clickForTwitter}>Twitter</li>
					<li  class="footer_text_link"on:click={clickForDiscord}>Discord</li>
					<li class="footer_text_link" on:click={clickForInstagram}>Instagram</li>
					<li class="footer_text_link"on:click={clickForMedium}>Medium</li>
					<li class="footer_text_link" on:click={clickForFacebook}>Facebook</li>
				</ul>
			</div>
			<!-- <div class="footer_form mt-10">
				<p class="bold footer_msg">Stay up to date with the latest Oni Ronin news.</p>
				<input class="input_underline z-50" type="text" placeholder="ENTER EMAIL ADDRESS" />
				<button on:click={sendEmail} class="base-btn footer_submit z-50">
					SIGN UP
				</button>
			</div> -->

		<!-- Desktop Layout Ends -->

		{:else}

		<!-- Mobile Layout Begins -->

		<div class="bighead_logo mt-4">
			<div class="inline contact_row mx-auto text-center z-50">
				<div class="footer_line ml-2"></div>
				<img src={Logo} alt="" class="footer_logo_mobile mt-16" />
					<div class="mb-32">
						<img src={Twitter} alt="" class="contact_icon_mobile" on:click={clickForTwitter} />
						<img src={Discord} alt="" class="contact_icon_mobile" on:click={clickForDiscord} />
						<img src={IG} alt="" class="contact_icon_mobile" on:click={clickForInstagram} />
						<img src={Medium} alt="" class="contact_icon_mobile" on:click={clickForMedium}/>
						<img src={FB} alt="" class="contact_icon_mobile" on:click={clickForFacebook} />
					</div>
					<!-- <div class="footer_form text-center mx-auto mt-10">
						<p class="bold footer_msg text-center">Stay up to date with the latest Oni Ronin news.</p>
						<div class="mx-auto">
							<input class="input_underline_mobile z-50 text-center" type="text" placeholder="ENTER EMAIL ADDRESS" />
						</div>
						<button on:click={sendEmail} class="base-btn footer_submit_mobile z-50 mx-auto">
							SIGN UP
						</button>
					</div> -->
					<!-- <div>
						<p class="copy_mobile mt-10 mb-52">Big Head Club Inc &copy; 2021</p>
					</div> -->
			</div>
		</div>
		<!-- Mobile Layout Ends -->
		{/if}
	</div>
</footer>
