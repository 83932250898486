<script>
	import { afterUpdate } from 'svelte'
	import {profile} from '../stores/metadata'
	import {state, data, useAlchemy} from '../stores/manager'
	import {data as data_alchemy} from '../stores/web3/alchemy/contracts'
	import SearchBar from '../components/SearchBar.svelte'
	import Spinner from '../components/shared/Spinner.svelte'

	export let showProfile = false;

	let loading = false;

	const requested = () => {
		showProfile = false;
		loading = true;
		setTimeout(() => {
			showProfile = true;
			loading = false;
		}, 1000);
	}

	const clearOnSearch = () => { showProfile = false; }

</script>

<div class="mt-10">
	<SearchBar on:getRonin={requested} on:clearSearch={clearOnSearch}/>
	<div class="text-center">
		{#if loading === true}
			<Spinner class="mx-auto mb-5"/>
		{/if}

		{#if showProfile === true}
			<h1  class="font-bold mb-5">{profile.name}</h1>
			{#if profile.owner !== "NullAddress"}
				<h1  class="font-bold mb-5">Owner: {profile.owner}</h1>
			{:else}
				<h1 class="font-bold mb-5">This NFT is unclaimed.</h1>
			{/if}
			<img class="mx-auto" src={profile.url} alt={profile.name} width="200px"/>
			<div class=" traits grid grid-cols-3 mt-5 mx-auto">
					{#each profile.traits as attr}
						<button class="trait_pill">
							<p class="trait_type">{attr.trait_type}</p>
							<p class="trait_name">{attr.value}</p>
						</button>
					{/each}
			</div>
		{/if}
	</div>
</div>