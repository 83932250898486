<script>
	import {FogTop} from '../../../public/assets/urls'
	import {FogMiddle} from '../../../public/assets/urls'
	import {FogBottom} from '../../../public/assets/urls'
</script>

	<div class="fog_high">
		<img src={FogBottom} class="fog_low" alt=""/>
	</div>
	<div class="fog_mid ">
		<img class="fog_mid" src={FogMiddle} alt=""/>
	</div>
	<div class="fog_low">
		<img class="fog_high" src={FogTop} alt=""/>
	</div>
