<script>

</script>

<div id="loading-spinner">
  <div class="spin-icon"></div>
</div>

<style>
#loading-spinner {
  display: inline;
  position: relative;
  z-index: 7;
}

#loading-spinner .spin-icon {
  margin: auto;
  width: 80px;
  height: 80px;

  border: solid 3px transparent;
  border-top-color: #d1c179;
  border-left-color:#d1c179;
  border-radius: 80px;

  -webkit-animation: loading-spinner 900ms linear infinite;
  -moz-animation:    loading-spinner 900ms linear infinite;
  -ms-animation:     loading-spinner 900ms linear infinite;
  -o-animation:      loading-spinner 900ms linear infinite;
  animation:         loading-spinner 900ms linear infinite;
}

@-webkit-keyframes loading-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes loading-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes loading-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes loading-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes loading-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}
</style>