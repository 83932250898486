<script>
	import {data, useAlchemy} from '../../stores/manager'
	import {state as state_contracts} from '../../stores/contracts'
	import {targetClicked, setKitsuneScroll, resetTarget, togglePanel, closeMenu, mobileMenuStatus, updateActivity} from '../../stores/ui'
	import {resetMintState, MINT_SUCCESS} from '../../stores/transactions'
	import {lock, unlock} from "../../stores/web3/wallet";
	import { navigateTo } from "svelte-router-spa";
	import BurgerMenu from './BurgerMenu.svelte'
	import {clickHandler} from './BurgerButton.svelte'
	import {fade} from 'svelte/transition'
	import {ToriiGates as Home} from '../../../public/assets/urls';

	import {target_network} from "../../stores/web3/targetNetwork";

	export let currentRoute;


	export function clickOutside(node) {

		const handleClick = event => {
			if (node && !node.contains(event.target) && !event.defaultPrevented) {
				node.dispatchEvent(
					new CustomEvent('click_outside', node)
				)
			}
		}

		document.addEventListener('click', handleClick, true)

		return {
			destroy() {
				document.removeEventListener('click', handleClick, true)
			}
		}

	}

	let isMint = false;
	let isAscend = false;
	let isKitsune = false;
	let isRoadmap = false;
	let isYourNFTs = false;
	let isHome = false;

	let networkAlert = false;
	let connectionError = false;

	let checkMintStatus;

	let w;

	const handleClickOutside = () => {
			$mobileMenuStatus === true ? updateActivity() : null
		}
	const clickAscend = () => {navigateTo('ascend')};
	const clickMint = () => {navigateTo('mint')};
	const clickKitsune = () => {
		navigateTo('kitsune');
		scrollTo(0, 0);
	};
	const clickRoadmap = () => {window.location.assign('/roadmap')};
	const clickYourNFTs = () => { !isYourNFTs ? navigateTo('yournfts') : window.location.assign('/yournfts')};
	const clickHome = () => {navigateTo('/')};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
		resetTarget()
	};

	const handleDebugPanel = () => { togglePanel() }

	$:{
				currentRoute.name === "/" ? isHome = true : isHome = false;
    }
	$:{
				currentRoute.name === "/mint" ? isMint = true : isMint = false;
    }
	$:{
				currentRoute.name === "/kitsune" ? isKitsune = true : isKitsune = false;
    }
	$:{
				currentRoute.name === "/roadmap" ? isRoadmap = true : isRoadmap = false;
    }
	$:{
				currentRoute.name === "/ascend" ? isAscend = true : isAscend = false;
    }
	$:{
				currentRoute.name === "/yournfts" ? isYourNFTs = true : isYourNFTs = false;
    }

		$: {
			checkMintStatus = $MINT_SUCCESS
			if (!isMint && checkMintStatus === true) { resetMintState() } 
		}

	$: $state_contracts === 'network' ? networkAlert = true : networkAlert = false;

	$: $state_contracts === 'error' ? connectionError = true : connectionError = false;

</script>

<svelte:window bind:innerWidth={w}/>

<header class="navigation" in:fade out:fade>



	<nav class="mobile_menu" use:clickOutside on:click_outside={handleClickOutside}>
		<BurgerMenu backgroundColor={"#000000"} burgerColor={'#f2ffff'} padding={'2%'} width={'260px'} >
			<!-- Create styles for hover states  -->
			<div class="text-left">
				{#if !isHome}
					<button class="base-blank-btn-mobile mobile-menu-padding" on:click={clickHome} on:click={handleClickOutside}>Home</button>
				{/if}
				<button class="base-blank-btn-mobile mobile-menu-padding " on:click={clickMint} on:click={handleClickOutside}>Mint</button>
				<button class="base-blank-btn-mobile mobile-menu-padding" on:click={clickKitsune} on:click={handleClickOutside}>Kitsune</button>
				<button class="base-blank-btn-mobile mobile-menu-padding" on:click={clickAscend} on:click={handleClickOutside}>Ascension</button>
				<!-- <button class="base-blank-btn-mobile" on:click={clickKitsune}>Kitsune</button> -->
				<button class="base-blank-btn-mobile mobile-menu-padding" on:click={clickRoadmap} on:click={handleClickOutside}>Roadmap</button>
				{#if $useAlchemy === false}
					<button class="base-blank-btn-mobile mobile-menu-padding" on:click={clickYourNFTs} on:click={handleClickOutside}>YOUR NFTS</button>
				{/if}


				{#if target_network.name !== "homestead"}
					<button class="base-blank-btn-mobile mobile-menu-padding" on:click={()=>{navigateTo("debug")}}>DEBUG</button>
				{/if}

			</div>

		</BurgerMenu>
	</nav>
	<nav>
		<ul class="nav_links hover:animate-pulse">
			<img src={Home} alt="" class="home_icon" on:click={isHome ? scrollToTop : clickHome}/>
			<button class="{isMint ? 'base-blank-btn-selected':'base-blank-btn'}" on:click={clickMint}>Mint</button>
			<!-- <button class="{isKitsune ? 'base-blank-btn-selected':'base-blank-btn'}" on:click={clickKitsune}>Kitsune</button> -->
			<button class="{isKitsune ? 'base-blank-btn-selected':'base-blank-btn'}" on:click={clickKitsune}>Kitsune</button>
			<button class="{isAscend ? 'base-blank-btn-selected':'base-blank-btn'}" on:click={clickAscend}>Ascension</button>
			<button class="{isRoadmap ? 'base-blank-btn-selected':'base-blank-btn'}" on:click={clickRoadmap}>Roadmap</button>
			<!-- <button class="{isBoatGame ? 'base-blank-btn-selected':'base-blank-btn'}">Boat Game</button> -->
			{#if $useAlchemy === false}
				<button class="{isYourNFTs ? 'base-blank-btn-selected':'base-blank-btn'}" on:click={clickYourNFTs}>Your NFTs</button>
			{/if}

			{#if target_network.name !== "homestead"}
				<button class="base-blank-btn" on:click={()=>{navigateTo("debug")}}>DEBUG</button>
			{/if}
		</ul>
	</nav>

	{#if $useAlchemy}
	<div class="wallet_controls {w < 500 ? "-mr-5":"-mr-12"}">
			<div class="status_light_off animate-pulse duration-75" on:click={handleDebugPanel}></div>
		<button class="base-btn-connect" on:click={unlock}>
			CONNECT WALLET
		</button>
	</div>
	{:else if $state_contracts === "loaded"}
	<div class="wallet_controls {w < 500 ? "-mr-5":"-mr-12"}">
		{#if networkAlert === false}
			<div class="status_light_on animate-pulse" on:click={handleDebugPanel}></div>
		{/if}
		<button class="{networkAlert === false ? "btn-connected":"btn-incorrect-network"}" on:click={lock}>
			{#if networkAlert === true} 
				<p class="animate-pulse text-yellow-200 mt-1 ">INCORRECT NETWORK</p>
			{:else if connectionError === true}
				<p class="animate-pulse text-red-200 mt-1 ">ERROR</p>
			{:else}
				CONNECTED
			{/if}
		</button>
	</div>
	{:else}
	<div class="wallet_controls {w < 500 ? "-mr-5":"-mr-12"}">
		{#if networkAlert === false}
			<div class="status_light_on animate-pulse" on:click={handleDebugPanel}></div>
		{/if}
		<button class="{networkAlert === false ? "btn-connected":"btn-incorrect-network"}" on:click={lock}>
			{#if networkAlert === true} 
				<p class="animate-pulse text-yellow-200 mt-1 ">INCORRECT NETWORK</p>
			{:else if connectionError === true}
				<p class="animate-pulse text-red-200 mt-1 ">ERROR</p>
			{:else}
				CONNECTED
			{/if}
		</button>
	</div>
	{/if}
</header>

<style>
	header {
		/* psudeo-effect! please do not remove unless restyling  */
		background-color: #000000bb !important; 
	}

	.mobile_menu {
		z-index: 999;
	}
</style>


